import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useRouter } from 'apis/history';
import { almostWhite, blue, blue10, blue60, white } from 'styles/colors';
import {
  FlexWrapper,
  H2,
  Logo,
  PrivateRoute,
  Seo,
  Small,
  Svg,
} from 'components';

import { ShippingForm } from './sections/ShippingForm';
import { PaymentsForm } from './sections/PaymentsForm';
import { mobile, useQuery } from 'styles/breakpoints';
import { useSearchParams } from 'hooks/use-search-params';

import { PRODUCTS } from 'assets/data/products';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedPlan } from 'state/user/effects';
import { AppState } from 'state/types';
import { Product } from 'state/funnel/types';

const Payments: React.FC = React.memo(navigation => {
  const { isMobile, isXsmDesktop } = useQuery();
  const { goToCheckout } = useRouter();
  const { products } = useSelector((s: AppState) => s.funnel);
  const dispatch = useDispatch();
  const productUrl = useSearchParams('product');

  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);

  useEffect(() => {
    if (!productUrl || !products) return;
    if (!Object.keys(PRODUCTS).includes(productUrl)) return;

    const findProd = products.find(({ key }: Product) => key === productUrl);

    if (findProd) {
      dispatch(updateSelectedPlan(findProd));
    }
  }, [dispatch, products, productUrl]);

  return (
    <>
      <Seo title="Payment | Pulsio" />
      <PrivateRoute>
        <Background>
          <Container>
            <Header>
              <FlexWrapper alignItems="center" justifyContent="space-between">
                <ArrowWrapper onClick={goToCheckout}>
                  <Svg
                    src="icons/arrow_left"
                    width="11"
                    height="9"
                    viewBox="0 0 11 9"
                    fill="none"
                  />
                </ArrowWrapper>
                <Logo notClickable />
                <HeaderPlaceholder />
              </FlexWrapper>
              <Small
                color={blue}
                textAlign="center"
                margin={
                  isMobile ? '2.625rem auto 0.625rem' : '3.875rem auto 0.625rem'
                }
              >
                CHECKOUT
              </Small>
              <H2
                color={blue}
                textAlign="center"
                margin={isMobile ? '0 auto 2.5rem' : '0 auto 5.75rem'}
              >
                One step closer to a better heart
              </H2>
            </Header>
            <FlexWrapper
              justifyContent="space-between"
              alignItems={isXsmDesktop ? 'center' : 'flex-start'}
              flexDirection={isXsmDesktop ? 'column' : 'row'}
            >
              <ShippingForm setIsFormFilled={setIsFormFilled} />
              <PaymentsForm disabled={!isFormFilled} />
            </FlexWrapper>
            {!isXsmDesktop ? null : (
              <Small color={blue60} padding="1.875rem 1.5rem 0">
                By clicking “Place Order,” you acknowledge you agree to the
                Pulsio Terms and Conditions and Privacy Policy. By making a
                purchase, you accept the KMG Medical Group Self Pay Agreement.
                By making a purchase, you agree to sign up for a Pulsio
                subscription. Your subscription will automatically renew, and
                your credit card will be charged the subscription fee. You can
                cancel or modify your subscription at any time.
              </Small>
            )}
          </Container>
        </Background>
      </PrivateRoute>
    </>
  );
});

Payments.displayName = 'Payments';

export default Payments;

const Background = styled.div`
  min-height: 100vh;
  padding: 1.25rem 0;
  background: ${almostWhite};
  padding-bottom: 3.75rem;
`;
const Container = styled.div`
  max-width: 63.125rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 63.125rem) {
    padding: 0;
  }
`;
const Header = styled.div`
  @media ${mobile} {
    padding: 0 1.5rem;
  }
`;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  border: 0.0625rem solid ${blue10};
  background: ${white};
`;
const HeaderPlaceholder = styled.div`
  width: 2.125rem;
`;
