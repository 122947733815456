import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import { handleError } from 'utils/error';
import { PRODUCTS } from 'assets/data/products';
import {
  almostWhite,
  blue,
  blue10,
  primary,
  text10,
  white50,
  white80,
} from 'styles/colors';
import {
  Circle,
  FlexWrapper,
  H3,
  Image,
  KiloPayments,
  Loader,
  Regular,
  Small,
} from 'components';
import { mobile } from 'styles/breakpoints';
import { oneTwo } from 'utils/metrics';

interface Props {
  disabled: boolean;
}

export const PaymentsForm: React.FC<Props> = ({ disabled }) => {
  const { goToOutOfStock } = useRouter();
  const { code, selected_plan } = useSelector((s: AppState) => s.user);
  const product = selected_plan ? PRODUCTS[selected_plan.key] : null;
  const handleSubmit = async (data?: {
    method: 'dropin' | 'dropin2' | 'card' | 'paypal';
  }) => {
    const method = data?.method;

    if (selected_plan && method) {
      Analytics.trackPaymentSubmit({ method, product: selected_plan.key });
    }

    goToOutOfStock();
  };
  const handleSuccess = async (order?: { order_id: string }) => {
    const orderId = order?.order_id;

    if (orderId) {
      Analytics.trackPurchase(orderId, 'USD');
    }

    goToOutOfStock();
  };

  if (!selected_plan || !product) {
    return null;
  }

  const price = selected_plan.prices[0];

  return (
    <Container>
      <H3 color={blue} textAlign="center">
        Your treatment plan
      </H3>
      <FlexWrapper
        alignItems="flex-start"
        justifyContent="space-between"
        margin="1.625rem auto 2.5rem"
      >
        <Circle size={4.625} color={almostWhite}>
          <Image src={product.photo} />
        </Circle>
        <RightColumn>
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <Regular color={blue}>{selected_plan.name} pack</Regular>
            <Regular color={blue}>${price.price.toFixed(2)}</Regular>
          </FlexWrapper>
          {price.discount && (
            <FlexWrapper alignItems="center" justifyContent="space-between">
              <Regular color={primary}>
                Discount (-{Math.round((price.discount * 100) / price.price)}%)
              </Regular>
              <Regular color={blue}>
                -${(price.discount || 0).toFixed(2)}
              </Regular>
            </FlexWrapper>
          )}
          <OrderSummaryItem alignItems="center" justifyContent="space-between">
            <Regular color={blue}>Shipping</Regular>
            <Regular color={blue}>FREE</Regular>
          </OrderSummaryItem>
          <OrderSummaryItem alignItems="center" justifyContent="space-between">
            <Regular color={blue}>
              Total billed every{' '}
              {oneTwo(product.durationInMonths, 'month', 'months', true)}
            </Regular>
            <Regular color={blue}>${price.final_price.toFixed(2)}</Regular>
          </OrderSummaryItem>
        </RightColumn>
      </FlexWrapper>
      <KiloPayments
        code={code}
        initialProduct={selected_plan}
        handleError={handleError}
        handleSubmit={handleSubmit}
        handleSuccess={handleSuccess}
      >
        <Loader />
      </KiloPayments>
      <Small textAlign="center" color={blue} margin="0 0 1.25rem">
        GUARANTEED SAFE CHECKOUT
      </Small>
      <PaymentsImage src="payments" />
      {disabled ? <DisabledOverlay /> : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 32.5rem;
  width: 100%;
  padding: 2.5rem 1.875rem 3.75rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${blue10};
  background: ${white80};

  @media ${mobile} {
    padding: 1.875rem 1.5rem 2.125rem;
    border: 0;
  }
`;
const PaymentsImage = styled(Image)`
  max-width: 25.875rem;
  width: 100%;
  margin: 0 auto;
`;
const RightColumn = styled.div`
  max-width: 22.25rem;
  width: 100%;
  @media ${mobile} {
    max-width: 15rem;
  }
`;
const OrderSummaryItem = styled(FlexWrapper)`
  padding-top: 1rem;
  margin-top: 0.75rem;
  border-top: 0.0625rem solid ${text10};
`;
const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: ${white50};
`;
