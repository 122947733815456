import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';

import { AppState } from 'state/types';
import { PRODUCTS } from 'assets/data/products';
import { Caption, FlexWrapper, Input, Small, Svg } from 'components';
import { blue, blue10, blue50, blue60, white } from 'styles/colors';
import { useQuery } from 'styles/breakpoints';
import { oneTwo } from 'utils/metrics';
import arrow from 'assets/icons/arrow.svg';

interface Props {
  setIsFormFilled: (value: boolean) => void;
}

const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'North Carolina',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const ShippingForm: React.FC<Props> = ({ setIsFormFilled }) => {
  const { isMobile, isXsmDesktop } = useQuery();
  const plan = useSelector((s: AppState) => s.user.selected_plan);
  const product = plan ? PRODUCTS[plan.key] : null;
  const [data, setData] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
  });
  const onTextUpdate = (e: { target: { name: any; value: any } }) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsFormFilled(
      Object.keys(data).every(key => {
        if (key === 'zipCode') {
          return data[key].length >= 5;
        }

        if (key !== 'address2') {
          return Boolean(data[key]);
        }

        return true;
      }),
    );
  }, [data]);

  if (!plan || !product) {
    return null;
  }

  return (
    <Container>
      <Caption color={blue} fontWeight="500" margin="0 0 1.25rem">
        Shipping Address
      </Caption>
      <Input
        type="text"
        name="address1"
        placeholder="Address 1"
        required={true}
        inputBackgroundColor={white}
        value={data.address1}
        onChange={onTextUpdate}
      />
      <Input
        type="text"
        name="address2"
        placeholder="Address 2"
        required={true}
        inputBackgroundColor={white}
        value={data.address2}
        onChange={onTextUpdate}
      />
      <Input
        type="text"
        name="city"
        placeholder="City"
        required={true}
        inputBackgroundColor={white}
        value={data.city}
        onChange={onTextUpdate}
      />

      <DropDown>
        <ArrowIcon />
        <StyledSelect
          name="state"
          empty={data.state === ''}
          onChange={onTextUpdate}
          value={data.state}
        >
          <StyledOption disabled value="">
            State
          </StyledOption>
          {US_STATES.map(st => (
            <StyledOption key={st} value={st}>
              {st}
            </StyledOption>
          ))}
        </StyledSelect>
      </DropDown>
      <Input
        type="text"
        name="zipCode"
        placeholder="Zip Code"
        required={true}
        inputBackgroundColor={white}
        value={data.zipCode}
        onChange={onTextUpdate}
        minLength={5}
      />
      <Input
        type="text"
        name="phoneNumber"
        placeholder="Phone number"
        required={true}
        inputBackgroundColor={white}
        value={data.phoneNumber}
        onChange={onTextUpdate}
      />
      {plan.is_subscription && (
        <>
          <Caption
            color={blue}
            fontWeight="500"
            padding={isMobile ? '1.625rem 0 0.75rem' : '2.375rem 0 1rem'}
          >
            Refill information
          </Caption>

          <Small color={blue} fontWeight="700">
            First Refill:{' '}
            {format(
              addMonths(new Date(), product.durationInMonths),
              'MMMM dd, yyyy',
            )}
          </Small>

          <Small
            color={blue60}
            margin={isMobile ? '0 0 2.25rem' : '0 0 0.875rem'}
          >
            Your treatment refills automatically every{' '}
            {oneTwo(product.durationInMonths, 'month', 'months', true)}. No
            action is required. You can pause or cancel the plan at any time.
          </Small>
        </>
      )}
      {isXsmDesktop ? null : (
        <Small margin={!plan.is_subscription ? '3rem 0 0' : ''} color={blue60}>
          By clicking “Place Order,” you acknowledge you agree to the Pulsio
          Terms and Conditions and Privacy Policy. By making a purchase, you
          accept the KMG Medical Group Self Pay Agreement.
          {plan.is_subscription
            ? ' By making a purchase, you agree to sign up for a Pulsio subscription. Your subscription will automatically renew, and your credit card will be charged the subscription fee. You can cancel or modify your subscription at any time.'
            : ''}
        </Small>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 24.125rem;
  width: 100%;
  padding: 0 1.5rem;
`;

const StyledSelect = styled.select<{ empty?: boolean }>`
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: ${blue};
  border: none;
  outline: none;
  border-radius: 1.875rem;
  border: 1px solid ${blue10};
  padding: 1rem 2.5rem 1rem 1.5rem;
  margin: 0 auto 1rem auto;

  ${({ empty }) =>
    empty &&
    css`
      color: ${blue50};
    `}

  appearance: none;
  background-color: ${white};
  position: relative;
`;

const StyledOption = styled.option`
  color: ${props => (props.disabled ? blue50 : blue)} !important;
`;

const DropDown = styled.div`
  position: relative;
`;

const ArrowIcon = styled.div`
  z-index: 1;
  border: solid ${blue50};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  pointer-events: none;

  transform: rotate(45deg);

  position: absolute;
  right: 1.5rem;
  top: 30%;
`;
