export const PRODUCTS = {
  'pulsio-1-subscription': {
    servings: 30,
    durationInMonths: 1,
    bottlesDelivered: 1,
    photo: 'product_1_month',
    isSpecialOffer: false,
  },
  'pulsio-3-subscription': {
    servings: 90,
    durationInMonths: 3,
    bottlesDelivered: 3,
    photo: 'product_3_month',
    isSpecialOffer: false,
  },
  'pulsio-6-subscription': {
    servings: 180,
    durationInMonths: 6,
    bottlesDelivered: 6,
    photo: 'product_6_month',
    isSpecialOffer: true,
  },
  'pulsio-1-one-time': {
    servings: 30,
    durationInMonths: 1,
    bottlesDelivered: 1,
    photo: 'product_1_month',
    isSpecialOffer: false,
  },
  'pulsio-3-one-time': {
    servings: 90,
    durationInMonths: 3,
    bottlesDelivered: 3,
    photo: 'product_3_month',
    isSpecialOffer: false,
  },
  'pulsio-6-one-time': {
    servings: 180,
    durationInMonths: 6,
    bottlesDelivered: 6,
    photo: 'product_6_month',
    isSpecialOffer: true,
  },
};
